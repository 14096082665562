import { Pipe, PipeTransform } from '@angular/core';
import { VerificationStatus } from '@core/models';
import { PublishStatusEnum } from '@core/enums';
import { getPublishStatus } from '@core/utils';

@Pipe({
  name: 'publishedStatus',
})
export class PublishedStatusPipe implements PipeTransform {
  transform(isPublished: boolean): VerificationStatus {
    const statusEnum = isPublished ? PublishStatusEnum.Published : PublishStatusEnum.Unpublished;
    return getPublishStatus(statusEnum);
  }
}
